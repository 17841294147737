import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import Breadcrumbs from "src/components/Breadcrumbs"
import SimpleText from "src/components/simple-text"
import SubpageBanner from "src/components/SubpageBanner"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {url: "womens-health-impact/getting-excellerator", handle: "Getting ExCellerator in Your Facility"},
]

const ClinicalResults = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-6.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const background = data.background.childImageSharp.fluid
      const one = data.one.childImageSharp.fixed

      return (
        <Layout>
          <SubpageBanner image={background} heading="How do I get the exCellerator?" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText
                  copy="To begin using the exCellerator, simply contact your laboratory. They will know the proper steps for ordering in your individual practice."
                />
                <SimpleText>
                  <p>
                  To begin using the exCellerator, simply send us your contact information and we will connect you to a vendor. They will know the proper steps for ordering in your individual practice.
Should your lab or practice have issues receiving exCellerator in your regular orders, please don’t hesitate to <Link to="contact">contact us!</Link>
                  </p>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default ClinicalResults
